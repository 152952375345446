/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "af48e6ceecae41539b1e77a92ba7bfc6",
    "aws_mobile_analytics_app_region": "eu-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "af48e6ceecae41539b1e77a92ba7bfc6",
            "region": "eu-west-2"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "af48e6ceecae41539b1e77a92ba7bfc6",
                "region": "eu-west-2"
            }
        }
    },
    "aws_appsync_graphqlEndpoint": "https://t2zbwxgl2ngrdfowzzrqq2zcsm.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-73osaokt25g6rhm7hiwrbv3yb4",
    "aws_cognito_identity_pool_id": "eu-west-2:ecdf0619-3615-4572-a17a-daabc7ac1cd7",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_7E2IQ9cGF",
    "aws_user_pools_web_client_id": "g7umgamfgj80jdqcq8vkerv45",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "acecuraapp-community-hosting-staging",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "https://d2fqbek781rnov.cloudfront.net",
    "aws_user_files_s3_bucket": "acecuraapp-storage180506-staging",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
